/* eslint-disable */

import React, { Suspense } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { LoadFile } from './patientInfodata';
import { selectAuth, selectCache, selectUXPlatform, selectVersion } from '../counter/counterSlice';
import { CacheManager } from '../counter/counterAPI';

const NavBar = React.lazy(() => import('../../components/navBar'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const Footer = React.lazy(() => import('../../components/footer'));
const PdfModal = React.lazy(() => import('../../components/pdfModal'));

export function PatientInfoJS(props) {
  const { pathname, search, push } = props;
  const dartSelectors = {};

  dartSelectors.auth = useSelector(selectAuth);
  dartSelectors.ccmsversion = useSelector(selectVersion);
  dartSelectors.uxPlatform = useSelector(selectUXPlatform);
  dartSelectors.cache = useSelector(selectCache);
  dartSelectors.dispatch = useDispatch();
  dartSelectors.iDB = new CacheManager({name: dartSelectors.ccmsversion.selected});

  LoadFile(dartSelectors, 'patient');
  return (
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
      <TopicBanner pathname={pathname} search={search} push={push} />
        <div className="container">
          <div className="container tg_guideline">
            <div className="row">
              <div id="patientId">
                <div className="col-lg-12">
                  <p className="mTop20px"><strong>You will need Adobe Reader to view the PDF files. You can <a title="Download Adobe Reader for free" href="http://get.adobe.com/uk/reader/" target="_blank">download Adobe Reader</a> free of charge.</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
      <PdfModal />
    </Suspense>
  );
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(PatientInfoJS);