export const validateAlphaNumericAll = (alphanum) => {
  var reg = /^[A-Za-z0-9.\-\s!@#$%^'*()=+,]*$/;  
  if (reg.test(alphanum)) {
    return true;
  }
  else {
    return false;
  }
}

export const removeSpecialCharacters = (str) => {
  if (!str) {
    return '';
  }
  const pattern = /[^a-zA-Z0-9_\s]/g;
  const cleanStr = str.replace(pattern, '');

  return cleanStr;
};
