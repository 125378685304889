import React from 'react';
const NoResults = (props)=>{
  return(
    <ul id="drug-indicator-container">
        <li key={"noresults-"+0} data-key={"noresults-"+0} style={{ borderBottom: "0!important", listStyle: "none" }}>
            <h5 className='searchContentH'>No results found.</h5>
        </li>
    </ul>
  )
}
export default React.memo(NoResults);