/* eslint-disable */

import React from 'react';
import $ from 'jquery';
import { renderToString/*,renderToStaticMarkup*/ } from 'react-dom/server';
import bsacalc_formula from './images/body_surface_area_transparent.png'
import ccbwcalc_formula from './images/etg_abg15_appendix_figure1_adult-renal-function.png'
import idealweight_m from './images/etg_abg15_appendix_figure6_male-ideal-body-weight.png'
import idealweight_f from './images/etg_abg15_appendix_figure7_female-ideal-body-weight.png'
import leanweight_m from './images/etg_abg15_appendix_figure3_male-lean-body-weight.png'
import leanweight_f from './images/etg_abg15_appendix_figure4_female-lean-body-weight.png'
import bmi from './images/etg_abg15_appendix_figure5_body-mass-index.png'
import gfr from './images/etg_abg15_appendix_figure1_children-renal-function.png'

export const Bsacalc = () => {
    return (
        <div>
          <h4>Body surface area calculator</h4>
          <p>Enter the patient details into the calculator below. The body surface area is calculated using the following formula:</p>
          <p><img alt="bsacalc_formula" src= { bsacalc_formula } id="bsacalc_formula"/></p>
          <form name="calcform" className="form-horizontal form-content-calc">
            <div className="form-group mTop12px"><label placeholder="Height" className="control-label col-lg-4 col-sm-6 col-xs-6">Height: </label>
              <div className="col-lg-3 col-sm-3 col-xs-6">
                <div className="col-lg-9 noLeftPadding noRightPadding">
                    <input name="height_in" type="text" defaultValue={""} className="form-control" id="heightCM"/>
                </div>
                <div className="col-lg-3">cm</div>
              </div>
            </div>
            <div className="form-group mTop12px">
                <label placeholder="Weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Weight: </label>
                <div className="col-lg-3 col-sm-3 col-xs-6">
                    <div className="col-lg-9 noLeftPadding noRightPadding">
                        <input name="mass_in" type="text" defaultValue={""} className="form-control" id="weightKG"/>
                    </div>
                    <div className="col-lg-3">kg</div>
                </div>
            </div>
            <div className="form-group mTop12px">
                <div className="col-sm-offset-4 col-sm-8 btncalc-container">
                    <input name="new_button1" type="button" defaultValue={"Calculate"} id="calcBSA" className="btn btn-info btn-sm borderNone btn-calc btn-custom"/>
                    <input name="new_button" type="reset" defaultValue={"Clear form"} id="clrBSA" className="btn btn-default btn-sm borderNone btn-clr btn-custom"/>
                </div>
            </div>
            <div className="form-group mTop12px">
                <label placeholder="Body surface area" className="control-label col-lg-4 col-sm-6 col-xs-6">Body surface area: </label>
                <div className="col-lg-3 col-sm-3 col-xs-6">
                    <div className="col-lg-9 noLeftPadding noRightPadding">
                        <input name="bsa_out" type="text" className="form-control" id="bsam2" readOnly/>
                    </div>
                    <div className="col-lg-3">m<sup>2</sup></div>
                </div>
            </div>
          </form>
        </div>
    )
}

export const Ccbwcalc = () => {
    return (
        <div>
            <h4>Creatinine clearance calculator for adults</h4>
            <p>The calculator below provides an estimate of creatinine clearance using the Cockcroft-Gault formula. Therefore:</p>
            <p> The calculator should only be used for patients older than 18 years. </p>
            <p> For patients who are not overweight, <b>actual body weight</b> can
            be used in the calculator. </p>
            <p> For patients who are overweight, <a href="#lbwcalc" data-toggle="tab">Lean body weight calculator</a> is preferred, because creatinine is
            a muscle breakdown product; however, <a href="#ibwcalc" data-toggle="tab">Ideal body weight calculator</a> can be used. </p>
            <p> The calculator does not accurately predict creatinine clearance in
            patients who have unstable renal function (eg patients in intensive
            care, patients with acute renal impairment, patients with febrile
            neutropenia); a measured (urinary) creatinine clearance is most accurate
            in this situation. </p>
            <p> The calculator should not be used for patients who have low muscle
            mass (eg cachectic patients).</p>
            <form name="creatinine" action="" className="form-horizontal form-content-calc">
                <div className="form-group mTop12px">
                    <label placeholder="weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Weight :</label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input type="text" maxLength="4" name="w" size="5" className="form-control" id="c2kg"/>
                        </div>
                        <div className="col-lg-3">kg</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="age" className="control-label col-lg-4 col-sm-6 col-xs-6">Age :</label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input type="text" maxLength="3" name="age" size="5" className="form-control" id="c2years"/>
                        </div>
                        <div className="col-lg-3">years</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="sex" className="control-label col-lg-4 col-sm-6 col-xs-6">Sex :</label>
                    <div className="col-lg-7 col-sm-3 col-xs-6">
                        <label className="radio-inline"><input type="radio" name="sex" checked="checked" value="Male" id="c2sex"/> Male</label>
                        <label className="radio-inline"><input type="radio" name="sex" value="Female"/> Female</label>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="serum creatinine" className="control-label col-lg-4 col-sm-6 col-xs-6">Serum creatinine :</label>
                    <div className="col-lg-7 col-sm-3 col-xs-6">
                        <div className="col-lg-3 noLeftPadding noRightPadding">
                            <input type="text" maxLength="6" name="Cr" size="5" className="form-control" id="c2serum"/>
                        </div>
                    <div className="col-lg-5">
                        <select name="SerumUnit" className="form-control" id="c2unit1">
                            <option value="0.001|0">micromoles/L</option>
                            <option value="1|0">millimoles/L</option>
                        </select>
                    </div>
                </div>
                </div>
                <div className="form-group mTop12px">
                <div className="col-sm-offset-4 col-sm-8 btncalc-container">
                    <input name="calculate_Cr" id="ccbwcalc_compute" type="button" value="Calculate" className="btn btn-info btn-sm borderNone btn-calc btn-custom"/>
                    <input type="reset" name="Clear_Cr" value="Clear form" className="btn btn-default btn-sm borderNone btn-clr btn-custom"/>
                </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="serum creatinine" className="control-label col-lg-4 col-sm-6 col-xs-6">Creatinine clearance estimate :</label>
                    <div className="col-lg-7 col-sm-3 col-xs-6">
                        <div className="col-lg-3 noLeftPadding noRightPadding">
                            <input type="text" maxLength="5" name="clear" size="5" className="form-control" readOnly id="c2estimate"/>
                        </div>
                        <div className="col-lg-5">
                            <select name="ResUnit" onchange="creatCalc(this.form, SerumUnit, ResUnit);" className="form-control" id="c2unit2">
                                <option value="1|0">mL/min</option>
                                <option value="0.01666|0">mL/sec</option>
                            </select>
                        </div>
                </div>
                </div>
            </form>
            <p>This calculator is based on the Cockcroft-Gault formula:</p>
            <table className="table table-striped table-bordered ">
                <tbody>
                <tr>
                    <td>
                    <p>
                        <img src= { ccbwcalc_formula } id = "ccbwcalc_formula" alt="" border="0"/>
                    </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export const Ibwcalc = () => {
    return (
        <div>
            <h4>Ideal body weight calculator</h4>
            <form name="IdealBodyWgt_form" action="" className="form-horizontal form-content-calc">
                <div className="form-group mTop12px">
                    <label placeholder="height" className="control-label col-lg-4 col-sm-6 col-xs-6">Height :</label>
                    <div className="col-lg-7 col-sm-3 col-xs-6">
                        <div className="col-lg-3 noLeftPadding noRightPadding">
                            <input type="text" name="Height_param" size="5" defaultValue="" maxLength="4" className="form-control" id="c3height"/>
                        </div>
                        <div className="col-lg-5">
                            <select name="Height_unit" size="1" className="form-control" id="c3unit">
                                <option value="cm">cm</option>
                                <option value="inches">inches</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="sex" className="control-label col-lg-4 col-sm-6 col-xs-6">Sex :</label>
                    <div className="col-lg-7 col-sm-3 col-xs-6">
                        <label className="radio-inline"><input type="radio" name="Sex_radio" checked="checked" value="Male|23" id="idw_male"/> Male </label>
                        <label className="radio-inline"><input type="radio" name="Sex_radio" value="Female|21.5" id="idw_female"/> Female </label>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <div className="col-sm-offset-4 col-sm-8 btncalc-container">
                        <input type="button" value="Calculate" id="ibwcalc_compute" name="button2" className="btn btn-info btn-sm borderNone btn-calc btn-custom"/>
                        <input type="reset" name="Clear_weight" value="Clear form" className="btn btn-default btn-sm borderNone btn-clr btn-custom"/>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="Ideal body weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Ideal body weight :</label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input type="text" name="IBW_param" size="6" onFocus="blur()" maxLength="4" className="form-control" readOnly id="c3kg"/>
                        </div>
                        <div className="col-lg-3">kg</div>
                    </div>
                </div>
            </form>
            <p>This calculator is based on the formula:</p>
            <table className="table table-striped table-bordered">
            <tbody>
                <tr>
                    <td>
                        <p className="eptabletext">
                            <img src= {idealweight_m} className="ibwcalc_formula" alt="" width="465" height="40" border="0"/>
                        </p>
                        <p className="eptabletext">
                            <img src= {idealweight_f} className="ibwcalc_formula" alt="" width="465" height="40" border="0"/>
                        </p>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
    )
}

export const Lbwcalc = () => {
    return (
        <div>
            <h4>Lean body weight calculator</h4>
            <form name="LeanBodyWgt_form" className="form-horizontal form-content-calc">
                <div className="form-group mTop12px">
                    <label placeholder="Sex" className="control-label col-lg-4 col-sm-6 col-xs-6">Sex: </label>
                    <div className="col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <label className="radio-inline"><input name="lbwSex" type="radio" checked="checked" value="male" id="lbwSex"/>Male</label>
                            <label className="radio-inline"><input name="lbwSex" type="radio" value="female"/>Female</label>
                        </div>
                    </div>
                </div>
                <div className="form-group mTop12px"><label placeholder="Height" className="control-label col-lg-4 col-sm-6 col-xs-6">Height: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="lbwHeight" type="text" size="10" className="form-control" id="lbwHeight"/>
                        </div>
                        <div name="gfrSeCr" className="col-lg-3">cm</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="Actual body weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Actual body weight: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="lbwWeightActual" type="text" size="10" className="form-control" id="lbwWeightActual"/>
                        </div>
                        <div name="gfrSeCr" className="col-lg-3">kg</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <div className="col-sm-offset-4 col-sm-8 btncalc-container">
                        <input name="lbwCalc" type="button" value="Calculate" id="lbwcalc_compute" className="btn btn-info btn-sm borderNone btn-calc btn-custom"/>
                        <input name="lbwReset" type="reset" value="Clear form" className="btn btn-default btn-sm borderNone btn-clr btn-custom"/>
                    </div>
                </div>
                <div className="form-group mTop12px"><label placeholder="Lean body weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Lean body weight: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="leanBodyWeight" type="text" size="10" readOnly className="form-control" id="leanBodyWeight"/>
                        </div>
                        <div name="gfrSeCr" className="col-lg-3">kg</div>
                    </div>
                </div>
            </form>
            <p>This calculator is based on the formula:</p>
            <table className="table table-striped table-bordered">
                <tbody>
                    <tr>
                        <td>
                            <p>
                                <img src={leanweight_m} id="lean_m" className="lbw_calcs" alt="" border="0"/>
                                <br/>
                                <img src={leanweight_f} id="lean_f" className="lbw_calcs" alt="" border="0"/>
                                <br/>
                                <img src={bmi} id="bmi" alt="" className="lbw_calcs" border="0"/>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export const Gfrcalc = () => {
    return (
        <div>
            <h4>Glomerular filtration rate (GFR) calculator for children</h4>
            <p>The calculator below provides an estimate of glomerular filtration rate. It should only be used for children older than 1 year.</p>
            <form name="creatinine" className="form-horizontal form-content-calc">
                <div className="form-group mTop12px"><label placeholder="weight" className="control-label col-lg-4 col-sm-6 col-xs-6">Height: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="gfr_height" type="text" size="15" value="" className="form-control" id="gfrHeight1"/>
                        </div>
                        <div className="col-lg-3">cm</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="age" className="control-label col-lg-4 col-sm-6 col-xs-6">Serum creatinine: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="gfr_SeCr" type="text" size="15" value="" className="form-control" id="gfrSeCr1"/>
                        </div>
                        <div className="col-lg-3">micromol/L</div>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <div className="col-sm-offset-4 col-sm-8 btncalc-container">
                        <input name="gfrCalc" type="button" value="Calculate" id="gfrcalc_compute" className="btn btn-info btn-sm borderNone btn-calc btn-custom"/>
                        <input name="gfrReset" type="reset" value="Clear form" className="btn btn-default btn-sm borderNone btn-clr btn-custom"/>
                    </div>
                </div>
                <div className="form-group mTop12px">
                    <label placeholder="GFR estimate" className="control-label col-lg-4 col-sm-6 col-xs-6">GFR estimate: </label>
                    <div className="col-lg-3 col-sm-3 col-xs-6">
                        <div className="col-lg-9 noLeftPadding noRightPadding">
                            <input name="gfrEstimate" type="text" size="10" readOnly="readonly" className="form-control" id="gfrEstimate"/>
                        </div>
                        <div name="gfrEstimate" className="col-lg-3">mL/min/1.73&nbsp;m<sup>2</sup></div>
                    </div>
                </div>
            </form>
            <p>This calculator is based on the modified Schwartz formula:</p>
            <table className="table table-striped table-bordered">
                <tbody>
                    <tr>
                        <td>
                            <p>
                                <img src={gfr} id="gfr_formula" alt="" border="0"/>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export function OpenCalc(calc) {
    let renderCalc = "";
    $('.calc-content').attr("id",calc);
    if(calc === "bsacalc") {  
      renderCalc = renderToString(<Bsacalc/>);
      $('#bsacalc').html(renderCalc);
      $('#bsacalc_li').addClass('active');
      $('#bsacalc').addClass('active');
  
      /* disable other calculators */
      $('#ccbwcalc_li').removeClass('active');
      $('#ccbwcalc').removeClass('active');
      $('#ibwcalc_li').removeClass('active');
      $('#ibwcalc').removeClass('active');
      $('#lbwcalc_li').removeClass('active');
      $('#lbwcalc').removeClass('active');
      $('#gfrcalc_li').removeClass('active');
      $('#gfrcalc').removeClass('active');
      
    }
    else if(calc === "ccbwcalc") {
      renderCalc = renderToString(<Ccbwcalc/>);
      $('#ccbwcalc').html(renderCalc);
      $('#ccbwcalc_li').addClass('active');
      $('#ccbwcalc').addClass('active');
        if($('.dxp-header').length > 0) {
            $("#ccbwcalc_formula").attr("src","	/calculator/etg_abg15_appendix_figure1_adult-renal-function.png");
        }
  
      /* disable other calculators */
      $('#bsacalc_li').removeClass('active');
      $('#bsacalc').removeClass('active');
      $('#ibwcalc_li').removeClass('active');
      $('#ibwcalc').removeClass('active');
      $('#lbwcalc_li').removeClass('active');
      $('#lbwcalc').removeClass('active');
      $('#gfrcalc_li').removeClass('active');
      $('#gfrcalc').removeClass('active');
    } 
    else if(calc === "ibwcalc") {
      renderCalc = renderToString(<Ibwcalc/>);
      $('#ibwcalc').html(renderCalc);
      $('#ibwcalc_li').addClass('active');
      $('#ibwcalc').addClass('active');
  
      /* disable other calculators */
      $('#ccbwcalc_li').removeClass('active');
      $('#ccbwcalc').removeClass('active');
      $('#bsacalc_li').removeClass('active');
      $('#bsacalc').removeClass('active');
      $('#lbwcalc_li').removeClass('active');
      $('#lbwcalc').removeClass('active');
      $('#gfrcalc_li').removeClass('active');
      $('#gfrcalc').removeClass('active');
    } 
    else if(calc === "lbwcalc") {
      renderCalc = renderToString(<Lbwcalc/>);
      $('#lbwcalc').html(renderCalc);
      $('#lbwcalc_li').addClass('active');
      $('#lbwcalc').addClass('active');
  
      /* disable other calculators */
      $('#ccbwcalc_li').removeClass('active');
      $('#ccbwcalc').removeClass('active');
      $('#ibwcalc_li').removeClass('active');
      $('#ibwcalc').removeClass('active');
      $('#bsacalc_li').removeClass('active');
      $('#bsacalc').removeClass('active');
      $('#gfrcalc_li').removeClass('active');
      $('#gfrcalc').removeClass('active');
    }
    else if(calc === "gfrcalc") {
      renderCalc = renderToString(<Gfrcalc/>);
      $('#gfrcalc').html(renderCalc);
      $('#gfrcalc_li').addClass('active');
      $('#gfrcalc').addClass('active');
  
      /* disable other calculators */
      $('#ccbwcalc_li').removeClass('active');
      $('#ccbwcalc').removeClass('active');
      $('#ibwcalc_li').removeClass('active');
      $('#ibwcalc').removeClass('active');
      $('#lbwcalc_li').removeClass('active');
      $('#lbwcalc').removeClass('active');
      $('#bsacalc_li').removeClass('active');
      $('#bsacalc').removeClass('active');
    } 
  
}

function GlobalClear(){
    // Clear All Text Fields
    $('.calculators input[type="text"]').val('');
  
    // Reset Radio Buttons
    $('.calculators input[type="radio"]')[0].checked = true;
    $('.calculators input[type="radio"]')[2].checked = true;
    $('.calculators input[type="radio"]')[4].checked = true;

    // Reset Dropdown Options
    $("#c2unit1").val($("#c2unit1 option:first").val());
    $("#c2unit2").val($("#c2unit2 option:first").val());
    $("#c3unit").val($("#c3unit option:first").val());
}

function roundOne(number) {
    number=number*10;
    number=Math.round(number);
    number=number/10;

    return number;
 }

/* FUNCTIONS */
// BSA CALCULATOR
let body_surface_area_globalvar_height=-2;
let body_surface_area_globalvar_mass=-2;

$(document).on('click', '#calcBSA',function() {
    body_surface_area_globalvar_height=document.calcform.height_in.value; 
    body_surface_area_globalvar_mass=document.calcform.mass_in.value;
    document.calcform.bsa_out.value = "";
    if(body_surface_area_globalvar_height.trim() === ''){
        alert("Please enter a value for height.");
        document.calcform.height_in.value="";
        document.calcform.height_in.focus();
        return false;
    }
    else if (isNaN(body_surface_area_globalvar_height)) {
        alert("Please enter only numeric characters in the height field.");
        document.calcform.height_in.select();
        return false;
    }
    else if(body_surface_area_globalvar_mass.trim() === ''){
        alert("Please enter a value for weight.");
        document.calcform.mass_in.value="";
        document.calcform.mass_in.focus();
        return false;
    }
    else if(isNaN(body_surface_area_globalvar_mass)){
        alert("Please enter only numeric characters in the weight field.");
        document.calcform.mass_in.select();
        return false;
    }
    
    let bsaf_coef = 0.016667;
    let bsaf_hpow = 0.5;
    let bsaf_mpow = 0.5;
    if (body_surface_area_globalvar_height < 0 || body_surface_area_globalvar_mass < 0)
    {
        document.calcform.bsa_out.value = "";
        return;
    }

    let bsa = bsaf_coef * Math.pow(body_surface_area_globalvar_height, bsaf_hpow) * Math.pow(body_surface_area_globalvar_mass, bsaf_mpow);
    // Round to the nearest 0.01
    bsa = Math.round(bsa * 100) / 100;

    document.calcform.bsa_out.value = bsa.toString();
});

//CCBW CALCULATOR
$(document).on('click', '#ccbwcalc_compute', function(){
    let form = this.form;
    let SerumUnit = document.getElementById("c2unit1");
    let ResUnit = document.getElementById("c2unit2");
    creatCalc (form, SerumUnit, ResUnit);
});

$(document).on('change', '#c2unit2', function(){
    let form = this.form;
    let SerumUnit = document.getElementById("c2unit1");
    let ResUnit = document.getElementById("c2unit2");
    creatCalc (form, SerumUnit, ResUnit);
});

function creatCalc(form, serum_unit, result_unit) {
    let weight = Number(form.w.value);
	let age = Number(form.age.value);

	let unit_parts = serum_unit.options[serum_unit.selectedIndex].value.split('|');
	let cr  = Number(form.Cr.value) * parseFloat(unit_parts[0]) + parseFloat(unit_parts[1]);

	form.clear.value="";
	if (!checkNum(weight,"weight")) {
		form.w.select();
		form.w.focus();
		return false;
	}
	if(!saneWeight(weight)){
		form.w.select();
		form.w.focus();
		return false;
	}

	if (!checkNum(age,"age")) {
		form.age.select();
		form.age.focus();
		return false;
	}
	if(!saneAge(age)){
		form.age.select();
		form.age.focus();
		return false;
	}

	if (!checkNum(cr,"creatinine")) {
		form.Cr.select();
		form.Cr.focus();
		return false;
	}
	if(!saneSerum(cr)){
		form.Cr.select();
		form.Cr.focus();
		return false;
	}

	let clear = ((140 - age) * weight) / (814 * cr); //formula
	if (form.sex[1].checked) {	//determines the gender
		clear = clear * 0.85;
	}

	unit_parts = result_unit.options[result_unit.selectedIndex].value.split('|');
	clear      = clear * parseFloat(unit_parts[0]) + parseFloat(unit_parts[1]);
	
	if ( parseInt(unit_parts[0]) === 1 ){
		clear = rounding(clear,0);
	}
	else{
		clear = rounding(clear,2);
	}
		form.clear.value = clear;

	return true;
}

function checkNum(val,text) {
	if ((val === null) || (val === "")) {
		alert("Please enter a value for " + text + ".");
		return false;
	}
	else if (isNaN(val)) {
		alert("Please enter only numeric characters in the "+text+" field.");
		return false;
	}
	else if (val < 0) {
		alert("Negative "+text+" entered.\nPlease enter valid "+text+".");
		return false;
	}
	return true;
}

function rounding(number,decimal) {
    let multiplier = Math.pow(10,decimal);
    number = Math.round(number * multiplier) / multiplier;
    return number;
}

function saneWeight(weight)
{
    if (weight > 300)
    {
        alert('Weight more than 300kg entered.\nUse the Ideal Body Weight Calculator to determine appropriate weight for CrCl calculation.');
        return false;
    }
    return true;
}

function saneAge(age)
{
    if (age > 110)
    {
        alert('Age older than 110 years entered; are you sure?');
        return false;
    }
    else if (age < 18)
    {
        alert('Age entered is less than 18 years.\nNote that the Cockcroft-Gault equation is only designed for people older than 18 years.');
        return false;
    }
    return true;
}

function saneSerum(serum)
{
    if (serum > 10)
    {
        alert('Very large value entered for serum creatinine.');
        return false;
    }
    else if (serum < 0.005)
    {
        alert('Very small value entered for serum creatinine.');
        return false;
    }
    return true;
}

//IBW CALCULATOR
$(document).on('click', '#ibwcalc_compute', function(){
    IdealBodyWgt_TG();
});

function IdealBodyWgt_TG() {
    let doCalc = true;
    let ibwForm = document.IdealBodyWgt_form;
    ibwForm.IBW_param.value=''; 
    if(ibwForm.Height_param.value.trim() === '') {
        alert('Please enter a value for height.');
        ibwForm.Height_param.select();
        return false;
    }
    if(isNaN(ibwForm.Height_param.value)) {
        alert('Please enter only numeric characters in the height field.');
        ibwForm.Height_param.select();
        return false;
    }

    let param_value = parseFloat(ibwForm.Height_param.value);
    let Ideal_weight = "";
    let Sex = "";
    let M_over = "";
    let Height = param_value;
    if (ibwForm.Sex_radio[0].checked)
        Sex = 50;   //Male
    else if (ibwForm.Sex_radio[1].checked)
        Sex = 45.5; //Female

    if(ibwForm.Height_unit.selectedIndex === 0) { //centimeters
        if (Height <= 152){
            Ideal_weight = Sex;
        }
        else{
            M_over = Height - 152;
            Ideal_weight = Sex + (.90 * M_over);
        }

        if (Height >= 300){
            alert('Very large height entered.');
            ibwForm.Height_param.select();
            return false;
        }
        if (Height <= 100){
            alert('Very small height entered.');
            ibwForm.Height_param.select();
            return false;
        }
    }

    if(ibwForm.Height_unit.selectedIndex === 1) { //inches
        if (Height <= 60){
            Ideal_weight = Sex;
        }
        else{
            M_over = Height - 60;
            Ideal_weight = Sex + (2.3 * M_over);
        }

        if (Height >= 120){
            alert('Very large height entered.');
            ibwForm.Height_param.select();
            return false;
        }
        if (Height <= 40){
            alert('Very small height entered.');
            ibwForm.Height_param.select();
            return false;
        }

    }
    let IBW =  roundOne(Ideal_weight);
    if (doCalc) {
        ibwForm.IBW_param.value = (IBW / 1);
    }
}

//LBW CALCULATOR
$(document).on('click', '#lbwcalc_compute', function(){
    calculate_lean_body_weight();
});

function calculate_lean_body_weight(){
    var lbw_sex = (document.getElementById("lbwSex").checked) ? "male" : "female";
    var lbw_height_cm = document.getElementById("lbwHeight").value;
    var lbw_height = lbw_height_cm/100;
    var lbw_actualWeight = document.getElementById("lbwWeightActual").value;
    document.getElementById("leanBodyWeight").value='';

    // check input values, abort if failure
    if (!sanity_check(lbw_height_cm, lbw_actualWeight)) return;

    var lbwBMI = lbw_actualWeight / (lbw_height*lbw_height);
    var leanBodyWeight = (lbw_sex === "male") ? (9270*lbw_actualWeight/(6680 + (216*lbwBMI))) : (9270*lbw_actualWeight/(8780 + (244*lbwBMI)));
    document.getElementById("leanBodyWeight").value = Math.round(leanBodyWeight);
}

function sanity_check(lbw_height_cm, lbw_actualWeight){
    // height 
    if(lbw_height_cm === "") {
        alert ("Please enter a value for height.");
        document.getElementById("lbwHeight").focus();
        return false;
    } 
    else if(isNaN(lbw_height_cm)){
    	alert ("Please enter only numeric characters in the height field.");
    	document.getElementById("lbwHeight").select();
        return false;
    }
    else if (lbw_height_cm < 100) {
        alert ("Very small height entered.");
        document.getElementById("lbwHeight").select();
        return false;
    } else if (lbw_height_cm > 220) {
        alert ("Very large height entered.");
        document.getElementById("lbwHeight").select();
        return false;
    }

    
    // weight 
    if (lbw_actualWeight === "") {
        alert ("Please enter a value for actual body weight.");
        document.getElementById("lbwWeightActual").focus();
        return false;
    } 
    else if(isNaN(lbw_actualWeight)){
    	alert ("Please enter only numeric characters in the actual body weight field.");
        document.getElementById("lbwWeightActual").select();
        return false;
    }
    else if (lbw_actualWeight < 40) {
        alert ("Very small actual weight entered.");
        document.getElementById("lbwWeightActual").select();
        return false;
    } else if (lbw_actualWeight > 200) {
        alert ("Very large actual weight entered.");
        document.getElementById("lbwWeightActual").select();
        return false;
    }
    return true;
}

//GFR CALCULATOR
$(document).on('click', '#gfrcalc_compute', function(){
    calculate_gfr();
});

function calculate_gfr(){
    var gfr_height = document.getElementById("gfrHeight1").value;
    var gfr_SeCr = document.getElementById("gfrSeCr1").value;
    document.getElementById("gfrEstimate").value ='';


    // check input values, abort if failure
    if (!sanity_check_gfr(gfr_height, gfr_SeCr)) return;

    var gfrEstimate = 36.5*gfr_height / gfr_SeCr;
    document.getElementById("gfrEstimate").value = Math.round(gfrEstimate);
    
}

function sanity_check_gfr(gfr_height, gfr_SeCr){
    // height 
    if (gfr_height === "") {
        alert ("Please enter a value for height.");
        document.getElementById("gfrHeight1").focus();
        return false;
    }
    else if(isNaN(gfr_height)){
    	alert ("Please enter only numeric characters into the height field.");
        document.getElementById("gfrHeight1").select();
        return false;
    }
    else if (gfr_height < 50) {
        alert ("Very small height entered.");
        document.getElementById("gfrHeight1").select();
        return false;
    } else if (gfr_height > 200) {
        alert ("Very large height entered.");
        document.getElementById("gfrHeight1").select();
        return false;
    }

    // serum creatinine
    if (gfr_SeCr === "") {
        alert ("Please enter a value for serum creatinine.");
        document.getElementById("gfrSeCr1").focus();
        return false;
    }
    else if(isNaN(gfr_SeCr)){
    	alert ("Please enter only numeric characters into the serum creatinine field.");
        document.getElementById("gfrSeCr1").select();
        return false;
    } else if (gfr_SeCr < 20) {
        alert ("Very small value entered for serum creatinine.");
        document.getElementById("gfrSeCr1").select();
        return false;
    } else if (gfr_SeCr > 1000) {
        alert ("Very large value entered for serum creatinine.");
        document.getElementById("gfrSeCr1").select();
        return false;
    }
    return true;
}
