//import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import favouriteReducer from '../store/reducers/favourite';
import searchReducer from '../store/reducers/search';
import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from "redux-thunk";

export const history = createBrowserHistory()

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  counter: counterReducer,
  favourite: favouriteReducer,
  search: searchReducer,
})

export const store = function configureStore(preloadedState) {
  // The below code is commented out to prevent dependency on Redux dev tools in Production
  // const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(createRootReducer(history),
  preloadedState, composeEnhancers(
    
  // const store = createStore(
  //   createRootReducer(history),
  //   preloadedState, compose(
      applyMiddleware(routerMiddleware(history), thunk)),
  )
  return store;
}