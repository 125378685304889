import React, { useEffect, useState } from 'react';
import { handleOpenAthensCallback } from '../../authConfig';

const CallbackPage = ({ history: { push } }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        setError('No authentication code received');
        return;
      }

      try {
        const authResult = await handleOpenAthensCallback(code);

        localStorage.setItem('auth', JSON.stringify(authResult));

        push('/profile');
      } catch (err) {
        setError(err.message);
      }
    };

    handleCallback();
  }, [push]);

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return <div>Authenticating...</div>;
};

export { CallbackPage };