import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRefineIndex } from '../../store/reducers/search';
import { setRefineIndex } from '../../store/reducers/search';

const RefineSearch = (props) => {
  const refineIndex = useSelector(selectRefineIndex);
  const dispatch = useDispatch();

  const [refineTerm, setRefineTerm] = useState(refineIndex);

  const withinSearchKeyPress = (event) => {
    if (event.charCode === 13) {
      dispatch(setRefineIndex(event.target.value));
    }
  };

  const handleRefineSearch = (event) => {
    dispatch(setRefineIndex(refineTerm));
  };

  useEffect(() => {
    setRefineTerm(refineIndex);
  }, [refineIndex]);


  return (
    <div id="searchWith" className="input-group searchWithMe">
      <div className="form-group">
        <input
          type="text"
          name="withinsearchinputbuttons"
          id="withinSearchInputButtons"
          placeholder="Search within results"
          className="form-control searchgBg"
          onChange={(e) => setRefineTerm(e.target.value)}
          value={refineTerm}
          onKeyPress={(e) => withinSearchKeyPress(e)}
        />
      </div>
      <span className="input-group-btn">
        <a type="button" id="filterSearch" className="btn nopadding" onClick={(e) => handleRefineSearch(e)}>
          <i className="flaticon-magnifying-glass34"></i>
        </a>
      </span>
    </div>
  )
}
export default React.memo(RefineSearch);