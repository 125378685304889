/* eslint-disable */
import React, { Suspense } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

const NavBar = React.lazy(() => import('../../components/navBar'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const Footer = React.lazy(() => import('../../components/footer'));


const ContactUs = (props) => {
  const { pathname, search, push } = props;
  return (
    <>
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
        <div className="container" style={{ height: '50vh' }}></div>
        <Footer />
    </Suspense>
    </>
  );
};

export default connect(null, { push })(ContactUs);