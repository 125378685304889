import { LogLevel } from "@azure/msal-browser";
import { UserManager } from 'oidc-client-ts';

export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_B2C_SIGNUP_SIGNIN_POLICY,
        forgotPassword: process.env.REACT_APP_B2C_FORGOT_PASSWORD_POLICY,
        editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE_POLICY,
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_B2C_SIGNUP_SIGNIN_AUTHORITY,
        },
        forgotPassword: {
            authority: process.env.REACT_APP_B2C_FORGOT_PASSWORD_AUTHORITY,
        },
        editProfile: {
            authority: process.env.REACT_APP_B2C_EDIT_PROFILE_AUTHORITY,
        },
    },
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITYDOMAIN,
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: process.env.REACT_APP_B2C_NAVIGATE_TO_LOGIN_REQUEST_URL, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ["openid","profile","email","address","phone"], // Add additional scopes as needed
};

export const profileEditPolicy = {
    authority: b2cPolicies.authorities.editProfile.authority, //"https://<your-tenant-name>.b2clogin.com/<your-tenant-name>.onmicrosoft.com/B2C_1_ProfileEdit",
    scopes: ["openid", "profile"], // Adjust scopes as needed
};

export const institutions = {
    scopes: ["openid","profile","email","address","phone"], // Add additional scopes as needed
};


const OPENATHENS_CONFIG = {
    authority: "https://login.openathens.net", 
    clientId: 'tg.org.au.oidc-app-v1.02620174-4e54-44b0-a614-f96a9f6c33b3',
    redirectUri: window.location.origin + '/openathens-callback',
    authorizationEndpoint: 'https://connect.openathens.net/oidc/auth',
    tokenEndpoint: 'https://connect.openathens.net/oidc/token',
    userinfoEndpoint: 'https://connect.openathens.net/oidc/userinfo',
    scope: 'openid profile email'
};

export const userManager = new UserManager(OPENATHENS_CONFIG);

export const initiateOpenAthensLogin = () => {
    userManager.signinRedirect();
};

export const handleOpenAthensCallback = async () => {
    const user = await userManager.signinRedirectCallback();
    return user;
};

export const logout = () => {
    userManager.signoutRedirect();
};

export const getUser = () => {
    return userManager.getUser();
};

// export const handleOpenAthensCallback = async (code) => {
//     try {
//         const tokenResponse = await fetch(OPENATHENS_CONFIG.tokenEndpoint, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 grant_type: 'authorization_code',
//                 code,
//                 redirect_uri: OPENATHENS_CONFIG.redirectUri,
//                 client_id: OPENATHENS_CONFIG.clientId,
//             })
//         });

//         if (!tokenResponse.ok) {
//             throw new Error(`Token request failed: ${tokenResponse.status}`);
//         }

//         const tokenData = await tokenResponse.json();
//         const { access_token } = tokenData;

//         // User info request
//         const userInfoResponse = await fetch(OPENATHENS_CONFIG.userinfoEndpoint, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Bearer ${access_token}`
//             }
//         });

//         if (!userInfoResponse.ok) {
//             throw new Error(`User info request failed: ${userInfoResponse.status}`);
//         }

//         const userInfo = await userInfoResponse.json();

//         return {
//             accessToken: access_token,
//             userInfo: userInfo
//         };
//     } catch (error) {
//         console.error('Authentication failed', error);
//         throw new Error('Authentication failed');
//     }
// };

const generateRandomState = () => {
    return Math.random().toString(36).substring(7);
};

export const useMsalInapp = false;
