import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { HashLink } from 'react-router-hash-link';
import { connect, useDispatch } from 'react-redux';
import { updateState } from '../counter/counterSlice';

const NavBar = React.lazy(() => import('../../components/navBar'));
const Footer = React.lazy(() => import('../../components/footer'));

const TablesAndFigures = (props) => {
  const { push } = props;
  const dispatch = useDispatch();
  const goToTable = (tableName, guidelineName) => {
    const guidelinePage = guidelineName.replace(/ /g,"_");
    const data = {
      ditaPath: `${guidelinePage}/`,
      queryString: {
        guidelinePage: encodeURIComponent(guidelineName),
        etgAccess: "true",
        topicfile: tableName,
      },
      XRefPath: `${guidelinePage}/tandf`,
      openSection: `${guidelinePage}/tandf`,
      displayBanner: true
    };

    dispatch(updateState(data));
  }

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <div className="container">
          <div className="row sectionBlockLayout">
            <div className="row-container">
              <div className="col-lg-12 columnBlockLayout">
                <div className="section-heading">
                  <h4>List of Tables and Figures - Guidelines</h4>
                </div>
                <ul className="list-unstyled guideline-areas">
                  <li>
                    <span className="dotIcon addictionmedicine"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Addiction Medicine') + '&topicfile=' + encodeURIComponent('addiction-medicine-tables-boxes-figures')}
                      onClick={() => goToTable('addiction-medicine-tables-boxes-figures', 'Addiction Medicine')}
                    >
                      Addiction
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right"></i>
                      </span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon antibiotic"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Antibiotic') + '&topicfile=' + encodeURIComponent('antibiotic-tables-boxes-figures')}
                      onClick={() => goToTable('antibiotic-tables-boxes-figures', 'Antibiotic')}
                    >
                      Antibiotic
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right"></i>
                      </span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon boneandmetabolism"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Bone and Metabolism') + '&topicfile=' + encodeURIComponent('bone-and-metabolism-tables-boxes-figures')}
                      onClick = {() => goToTable('bone-and-metabolism-tables-boxes-figures', 'Bone and Metabolism')}
                    >
                      Bone and Metabolism
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon cardiovascular"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Cardiovascular') + '&topicfile=' + encodeURIComponent('cardiovascular-tables-boxes-figures')}
                      onClick = {() => goToTable('cardiovascular-tables-boxes-figures', 'Cardiovascular')}
                    >
                      Cardiovascular
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon dermatology"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Dermatology') + '&topicfile=' + encodeURIComponent('dermatology-tables-boxes-figures')}
                      onClick = {() => goToTable('dermatology-tables-boxes-figures', 'Dermatology')}
                    >
                      Dermatology
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon developmentaldisability"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Developmental Disability') + '&topicfile=' + encodeURIComponent('developmental-disability-tables-boxes-figures')}
                      onClick = {() => goToTable('developmental-disability-tables-boxes-figures', 'Developmental Disability')}
                    >
                      Developmental Disability
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon diabetes"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Diabetes') + '&topicfile=' + encodeURIComponent('diabetes-tables-boxes-figures')}
                      onClick = {() => goToTable('diabetes-tables-boxes-figures', 'Diabetes')}
                      title=""
                    >
                      Diabetes
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon fatigue"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Fatigue') + '&topicfile=' + encodeURIComponent('fatigue-tables-boxes-figures')}
                      onClick = {() => goToTable('fatigue-tables-boxes-figures', 'Fatigue')}
                    >
                      Fatigue
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon gastrointestinal"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Gastrointestinal') + '&topicfile=' + encodeURIComponent('gastrointestinal-tables-boxes-figures')}
                      onClick = {() => goToTable('gastrointestinal-tables-boxes-figures', 'Gastrointestinal')}
                    >
                      Gastrointestinal
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon liverdisorders"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Liver Disorders') + '&topicfile=' + encodeURIComponent('liver-disorders-tables-boxes-figures')}
                      onClick = {() => goToTable('liver-disorders-tables-boxes-figures', 'Liver Disorders')}
                    >
                      Liver Disorders
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon neurology"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Neurology') + '&topicfile=' + encodeURIComponent('neurology-tables-boxes-figures')}
                      onClick = {() => goToTable('neurology-tables-boxes-figures', 'Neurology')}
                    >
                      Neurology
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon oralanddental"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Oral and Dental') + '&topicfile=' + encodeURIComponent('oral-and-dental-tables-boxes-figures')}
                      onClick = {() => goToTable('oral-and-dental-tables-boxes-figures', 'Oral and Dental')}
                    >
                      Oral and Dental
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon painandanalgesia"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Pain and Analgesia') + '&topicfile=' + encodeURIComponent('pain-and-analgesia-tables-boxes-figures')}
                      onClick = {() => goToTable('pain-and-analgesia-tables-boxes-figures', 'Pain and Analgesia')}
                    >
                      Pain and Analgesia
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon palliativecare"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Palliative Care') + '&topicfile=' + encodeURIComponent('palliative-care-tables-boxes-figures')}
                      onClick = {() => goToTable('palliative-care-tables-boxes-figures', 'Palliative Care')}
                    >
                      Palliative Care
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon psychotropic"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Psychotropic') + '&topicfile=' + encodeURIComponent('psychotropic-tables-boxes-figures')}
                      onClick = {() => goToTable('psychotropic-tables-boxes-figures', 'Psychotropic')}
                    >
                      Psychotropic
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon respiratory"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Respiratory') + '&topicfile=' + encodeURIComponent('respiratory-tables-boxes-figures')}
                      onClick = {() => goToTable('respiratory-tables-boxes-figures', 'Respiratory')}
                    >
                      Respiratory<span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon rheumatology"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Rheumatology') + '&topicfile=' + encodeURIComponent('rheumatology-tables-boxes-figures')}
                      onClick = {() => goToTable('rheumatology-tables-boxes-figures', 'Rheumatology')}
                    >
                      Rheumatology
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon sexualandreproductivehealth"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Sexual and Reproductive Health') + '&topicfile=' + encodeURIComponent('sexual-and-reproductive-health-tables-boxes-figures')}
                      onClick = {() => goToTable('sexual-and-reproductive-health-tables-boxes-figures', 'Sexual and Reproductive Health')}
                    >
                      Sexual and Reproductive Health
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon toxicologyandtoxinology"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Toxicology and Toxinology') + '&topicfile=' + encodeURIComponent('toxicology-and-toxinology-tables-boxes-figures')}
                      onClick = {() => goToTable('toxicology-and-toxinology-tables-boxes-figures', 'Toxicology and Toxinology')}
                    >
                      Toxicology and Toxinology
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon ulcerandwoundmanagement"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Ulcer and Wound Management') + '&topicfile=' + encodeURIComponent('ulcer-and-wound-management-tables-boxes-figures')}
                      onClick = {() => goToTable('ulcer-and-wound-management-tables-boxes-figures', 'Ulcer and Wound Management')}
                    >
                      Ulcer and Wound Management
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                  <li>
                    <span className="dotIcon wildernessmedicine"></span>
                    <HashLink
                      alt=""
                      data-toggle="tooltip"
                      to={'/viewTopic?etgAccess=true&guidelinePage=' + encodeURIComponent('Wilderness Medicine') + '&topicfile=' + encodeURIComponent('wilderness-medicine-tables-boxes-figures')}
                      onClick = {() => goToTable('wilderness-medicine-tables-boxes-figures', 'Wilderness Medicine')}
                      title=""
                    >
                      Wilderness Medicine
                      <span className="goto-arrow"><i className="fa banner-head-topic fa-angle-right"></i></span>
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {!window.Microsoft && <Footer />}
      </Suspense>
    </>
  );
};

TablesAndFigures.propTypes = {
  push: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

export default connect(mapStateToProps, { push })(TablesAndFigures);