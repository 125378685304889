import { createSlice } from '@reduxjs/toolkit';
import $ from "jquery";

const initialState = {
  openModalFlag: false,
  favourites: JSON.parse(localStorage.getItem('savedFavourites')),
};

const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    setOpenModalFlag: (state, action) => {
      const flag = action.payload;
      state.openModalFlag = flag;
      setTimeout(() => {
        if (flag) {
          $('.popover').hide();
          $("#myFavouritesModal").show();
          $("#myFavouritesModal").toggleClass('in');
          $(".modal-backdrop").show();
          $(".modal-backdrop").toggleClass('in');
        } else {
          $(".modal-backdrop").hide();
          $("#myFavouritesModal").toggleClass('in');
          $(".modal-backdrop").toggleClass('in');
          $("#myFavouritesModal").hide();
        }
      }, 10);
    },
    updateFavourites: (state) => {
      state.favourites = JSON.parse(localStorage.getItem('savedFavourites'));
    },
  }
});

export const selectOpenModalFlag = (state) => state.favourite.openModalFlag;
export const selectFavourites = (state) => state.favourite.favourites;

export const { setOpenModalFlag, updateFavourites } = favouriteSlice.actions;

export default favouriteSlice.reducer;
