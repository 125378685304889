/* eslint-disable */

import React, { Suspense, useEffect } from 'react'; 
import $ from 'jquery';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { OpenCalc } from './calc-content';

const NavBar = React.lazy(() => import('../../components/navBar'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const Footer = React.lazy(() => import('../../components/footer'));

$(document).on('click', '.calculators li a', function() {
  let calcURL = $(this).attr('name');
  window.location.hash = calcURL;
});

const Calculators = (props) => {
  const { pathname, search, push } = props;
  useEffect(() => {
    let getcalc = window.location.hash.slice(1);
    OpenCalc(getcalc);
    if(getcalc.indexOf('bsacalc') > -1 || getcalc === "") {
      window.location.hash = "#bsacalc";
    }
  });

  $('title').text('Calculators - Therapeutic Guidelines');
  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <div className="container">
          <div className="row calculators">
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4 tabLeftContainer">
              <ul className="nav nav-tabs tabs-left">
                <li id="bsacalc_li"><a onClick= {() => OpenCalc("bsacalc")} name ="bsacalc">Body surface area calculator</a></li>
                <li id="ccbwcalc_li"><a onClick= {() => OpenCalc("ccbwcalc")} name ="ccbwcalc">Creatinine clearance calculator for adults</a></li>
                <li id="ibwcalc_li"><a onClick= {() => OpenCalc("ibwcalc")} name ="ibwcalc" onMouseup="GlobalClear();">Ideal body weight calculator</a></li>
                <li id="lbwcalc_li"><a onClick= {() => OpenCalc("lbwcalc")} name ="lbwcalc" onMouseup="GlobalClear();">Lean body weight calculator</a></li>
                <li id="gfrcalc_li"><a onClick= {() => OpenCalc("gfrcalc")} name ="gfrcalc" onMouseup="GlobalClear();">Glomerular filtration rate (GFR) calculator for children</a></li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-9 col-lg-8 noLeftPadding tab-cal-container">
              <div className="tab-content tab-cal-content">
                <div className="tab-pane calc-content">
                </div>
              </div>
            </div>
          </div>
        </div>
        { window.location.hostname !== "testtgl.powerappsportals.com" &&
        <>
          {!window.Microsoft && <Footer />}
        </> }
       
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Calculators);