import { updateTOC } from './counterSlice';
import { history } from '../../app/store';

//import events from external files here.

import $ from "jquery";
import { rxhtmltag4jQuery } from './rxhtmltag';
rxhtmltag4jQuery();


export function UpdateURL(selectedHref, dartSelectors, tag = "", fromClick = false) {
    let XRefPath = selectedHref;
    if (!selectedHref.includes("#") &&
        window.parent.location.hash !== "" &&
        !window.parent.location.search.includes("sectionId")) {
        XRefPath += window.parent.location.hash;
    } else if (tag !== "" && tag !== "H2") {
        XRefPath += "#" + tag;
    }

    if (selectedHref.includes("../../")) {
        selectedHref = selectedHref.split("../../")[1];
    }
    selectedHref = selectedHref.replace(/\.\.\//g, "");

    if (selectedHref.includes("#")) {
        selectedHref = selectedHref.split("#")[0];
    }


    let tocPath = $('#tg_toc_id [href*="' + selectedHref + '"]').attr("tocpath");
    let mpsPath = $('#tg_toc_id [href*="' + selectedHref + '"]').attr("mpspath");
    if (mpsPath === undefined) {
        let section_id = selectedHref.split('-').slice(0, -1).join('-');
        mpsPath = $('#tg_toc_id [href*="' + section_id + '"]').attr("mpspath");
    }
    if (mpsPath !== undefined) {
        mpsPath = mpsPath.replace(")", "").replace("(", "");
    }

    let selectedTopic = $('#tg_toc_id [href*="' + selectedHref + '"]').attr("href");
    let urlConfig = localStorage.getItem("darturl") === "1" ? tocPath : mpsPath;

    let currentHash = window.parent.location.href.replace(window.parent.location.protocol + "//" + window.parent.location.host, "");

    processUrlUpdate(currentHash, urlConfig, dartSelectors, mpsPath, fromClick, selectedHref, window);

    $("xref,term").removeClass("active");
    dartSelectors.dispatch(updateTOC({ selectedTopic, XRefPath, dartSelectors, tag }));
    return {
        urlConfig,
        selectedTopic,
        XRefPath
    }
}

function processUrlUpdate(currentHash, urlConfig, dartSelectors, mpsPath, fromClick, selectedHref, window) {

    if (currentHash.replace(/\//g, '') !== urlConfig.replace(/\//g, '')) {

        if (urlConfig === '/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=acute-gastroenteritis&guidelinename=Antibiotic&sectionId=toc_d1e1473#toc_d1e1438') {
            urlConfig = '/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=acute-gastroenteritis&guidelinename=Antibiotic&sectionId=toc_d1e1455#toc_d1e1438';
        }

        if (dartSelectors.uxPlatform === "mobile") {
            let topicFileforMobile = mpsPath;
            topicFileforMobile = topicFileforMobile.split("topicfile")[1].split("=")[1].split("&")[0];
        }

        if (window.location.href.includes("topicTeaser")) {
            urlConfig = urlConfig.replace("viewTopic", "topicTeaser");

            setTimeout(() => {
                if (parseInt($('topicref.active:last').attr("level")) > 2 && $('.username').text() !== "" || parseInt($('topicref.active:last').attr("level")) == 1 && $('topicref.active:last').text().includes('calculator')) {
                    urlConfig = urlConfig.replace("topicTeaser", "viewTopic");
                    window.location = urlConfig;
                }
                else {
                    $('.tablesfigurestables').on('click', function () {
                        window.location.href = $(this).attr('mpspath');
                    });

                    $('.tablesfiguresfigures').on('click', function () {
                        window.location.href = $(this).attr('mpspath');
                    })
                }
            }, 500);

        }

        if (window.location.href.toLowerCase().includes("calculator") && window.location.href.includes("viewTopic")) {
            setTimeout(() => {
                let calcURL = $('[classname="bodyContentDiv"] xref').attr('href');
                window.location = calcURL;
            }, 1000);
        }
        if (fromClick && !urlConfig) {
            const currentURL = window.location.href;
            const url = new URL(currentURL);
            const queryParameters = url.searchParams;
            const modifiedHref = selectedHref.replace(/^(\.\.\/)+/, '');
            const splitHref = modifiedHref.split('/');
            if (splitHref.length === 3) {
                const guidelinePage = splitHref[0];
                const topicFile = splitHref[1];
                const sectionId = splitHref[2].replace(/\.dita$/, '');
                queryParameters.set('guidelinePage', guidelinePage);
                queryParameters.set('topicfile', topicFile);
                queryParameters.set('sectionId', sectionId);
                const updatedUrl = `${url.pathname}?${queryParameters.toString()}#${sectionId}`;
                history.push(updatedUrl);
            }
        } else {
            history.push(urlConfig);
        }

    }
}
//Move to separate file if there are a lot of functions.
//e.g. call this ditaTopicref.js for example and import above.
export function HandleTOCClick(dartSelectors) {
    $("concept>ditatitle, topic>ditatitle").on({
        click: function () {
            let selectedHref = $(this).closest("topicref").attr("href");
            console.log('selectedHref', selectedHref);
            if (selectedHref === 'Antibiotic/c_creatinine_clearance_calculator_for_adults.dita') {
                history.push('/calculator/ccbwcalc');
            } else if (selectedHref === 'Antibiotic/c_glomerular_filtration_rate_GFR_calculator_for_children.dita') {
                history.push('/calculator/gfrcalc');
            } else {
                UpdateURL(selectedHref, dartSelectors);
            }
        },
    });

    $("bookmap>ditatitle").on({
        click: function () {
            let currentElement = this;
            UpdateURL($(currentElement).closest("bookmap").find("chapter:eq(0)").attr("href"), dartSelectors);
        },
    });
}