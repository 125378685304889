import $ from "jquery";
import { cleanDITA } from './dita-clean';
import localforage from 'localforage';

let apiHost = "https://ccmsfiles.tg.org.au/";
// apiHost = "http://localhost:8000/";

export function flushAPICache() {
  return new Promise((resolve) =>{
    $.ajax({
      type: "DELETE",
      url: apiHost+"flush",
      success: function (data) {
        console.log("FLUSH API ", data);
      },
      error: function (data, error, status) {
        console.log(data, error, status);
      },
    }).then(function (data) {
      resolve({ data });
    });
  }
  );
}

export function statsAPICache() {
  return new Promise((resolve) =>{
    $.ajax({
      type: "PUT",
      url: apiHost+"stats",
      success: function (data) {
        console.log("STATS API ", data);
      },
      error: function (data, error, status) {
        console.log(data, error, status);
      },
    }).then(function (data) {
      resolve({ data });
    });
  }
  );
}

const isLocalNetwork = (hostname) => {
  return (
    hostname === 'localhost' ||
    hostname.startsWith('192.168.')
  );
};

export function fetchToken(sp) {
  return new Promise((resolve) =>{
    // if((window.parent.location.hostname.indexOf('tgldcdp.tg.org.au')>-1 && sp.dartSelectors.ccmsversion.selected!=="main") 
    //   || ((window.location.href.indexOf("localhost")>-1  && sp.dartSelectors.ccmsversion.selected!=="main")) 
    //   || ((window.location.href.indexOf("tgl.")>-1  && sp.dartSelectors.ccmsversion.selected!=="main"))
    //   || ((window.location.href.indexOf(".powerappsportals.com")>-1  && sp.dartSelectors.ccmsversion.selected!=="main"))
    //   || (isLocalNetwork(window.parent.location.hostname))) {
    if(1) { // disabled auth for now...
      let data = "Bearer eyJ0eXAiOiJKV1QiLCJub25jZSI6ImJoSz";
      console.log("auth:in");
      resolve({data});
      return;
    }

    $.ajax({
      type: "POST",
      url: apiHost+"https://login.microsoftonline.com/",
      success: function (authBearer) {
        // console.log("Auth token ", authBearer);
      },
      error: function (data, error, status) {
        console.log(data, error, status);
      },
    }).then(function (data) {
      // console.log("auth:from login.microsoft");
      resolve({ data })
    });
  }
  );
}

function getMimeType(fileName) {
  // List of file extensions to be treated as binary data
  const binaryExtensions = ['txt', 'dita', 'ditamap', 'xml', 'json'];
  const extension = fileName.split('.').pop().toLowerCase();
  
  return binaryExtensions.includes(extension) ? 'string' : 'base64'; // TODO: implement blob...
  // possible options: 'string', 'base64', 'binarystring', 'array', 'uint8array', 'arraybuffer', 'blob', 'nodebuffer'
}

function getFileType(extension) {
// List of file extensions to be treated as binary data
const binaryExtensions = {'.pdf':'application/pdf', 
                          '.jpg':'image/jpeg', 
                          '.png':'image/png', 
                          '.jpeg':'image/jpeg', 
                          '.svg':'image/svg+xml'};
return binaryExtensions[extension];
}

export async function fetchFileFromZip(contentFile,zip){
  // return new Promise((resolve) =>{  
    //const zip = await JSZip.loadAsync(zipArrayBuffer);
    contentFile = (contentFile.startsWith("/quicklinks"))?contentFile.slice(1):contentFile;
    //console.log(104,contentFile);
    const mimeType =  getMimeType(contentFile);
    const fileContent = await zip.file(contentFile).async(mimeType);
    const dataUri =(mimeType==="string")?fileContent: `data:${getFileType("."+contentFile.split(".").pop())};base64,${fileContent}`;

     //console.log(mimeType,fileContent,dataUri);

    contentFile = contentFile.split("/");
    if(contentFile.indexOf("quicklinks/") === -1){
      contentFile.shift(); // remove parent folder
    }
    contentFile = contentFile.join("/");
    let cleanDita = dataUri;

    try {
      if(mimeType==="string"){
          cleanDita = cleanDITA(cleanDita,contentFile);
      }
        // console.log(100,"=>Zip=>",mimeType,cleanDita,dataUri);
        console.log(100,"=>Zip=>",contentFile);
        // WriteToIDB(contentFile,cleanDita);
        return({data:cleanDita});
    } catch (err) {
        console.error(`Error storing file: ${contentFile}`, err);
    }
  // });
}

export function fetchData(sp) {
  return new Promise((resolve) =>{
    //https://graph.microsoft.com/v1.0/sites/589c23d3-622d-43d3-9ac3-ca4cad4566b4/drive/root:/Wilderness_Medicine/altitude-illness.dita
    //https://tgfoundation.sharepoint.com/:u:/s/Guidelines/EdRbDP31eGtHm_UpCT3OKiwBYHYENZcClLLf5QWjfS5PNw?e=cZh1qo
    //https://cors-anywhere.herokuapp.com/https://graph.microsoft.com/v1.0/sites/589c23d3-622d-43d3-9ac3-ca4cad4566b4/drive/items/01SHLLBQ6ULMGP35LYNNDZX5JJBE644KRM/content
    //01SHLLBQ4KDFMTEMEEHBCYDB7GQQ5VVIZO
    //https://cors-anywhere.herokuapp.com/https://graph.microsoft.com/v1.0/sites/589c23d3-622d-43d3-9ac3-ca4cad4566b4/drive/root:/Wilderness_Medicine/altitude-illness.dita:/content
    // console.log(sp);

    let headers = {};

    apiHost = "https://ccmsfiles.tg.org.au/"+sp.dartSelectors.ccmsversion.selected+"/";
    let fileURL = apiHost+sp.guideline+sp.topicfile;
    fileURL = fileURL.replace(sp.guideline+sp.guideline,sp.guideline);

    if(apiHost.indexOf("tglcontent")>-1)fileURL = fileURL.replace(sp.guideline,sp.guideline.replace(/_/g,"%20")); 
    // console.log(sp.guideline); load from beta.


    if(sp.topicfile.indexOf(".txt")>-1){
      if(localStorage.getItem("localtoc")!==null){//enable to R&D preprocessing.
        fileURL="https://cdn.ckeditor.com/Guidelines/preprocess/"+sp.topicfile.replace(".xml",".txt"); /*console.log(fileURL);*/
        headers={};
      }else{
        fileURL=apiHost+"preprocess/"+sp.topicfile.replace(".xml",".txt");
      }
    }
    
    // console.log(127,"counterAPI",fileURL,sp.topicfile);
    if(sp.topicfile.indexOf("drug_key")===0  
        && sp.dartSelectors.ccmsversion.selected!=="main"
        && (window.parent.location.href.indexOf("tgldcdp.tg.org.au")>-1 || fileURL.indexOf("tglcontent.tg.org.au")>-1)
        || sp.dartSelectors.ccmsversion.selected==="s2"){ // localhost/ for mps mobile
      fileURL = fileURL.replace("drug_key","preprocess/drug_key"); // placed here to bundle for downloads
      // console.log("refFiles>",fileURL);
    }

    if(sp.dartSelectors.uxPlatform==="mobile" && sp.dartSelectors.ccmsversion.selected!=="main"){ // copied preprocess into staticcontent offline zip aws tag.
      fileURL = fileURL.replace("preprocess/","staticcontent/preprocess/");
    }
    
    try {
      if(window.parent.location.href.indexOf("tgldcdp.tg.org.au")>-1){
      // if(fileURL.indexOf("tgldcdp.tg.org.au.")>-1){

        if(fileURL.indexOf("../../")>-1){ // for xrefcalls
          fileURL=fileURL.split("../../");
          fileURL = apiHost+fileURL[1];
          // console.log(fileURL);
        }

        let withoutAPIHost = fileURL.replace(apiHost,"");
        withoutAPIHost = withoutAPIHost.split("/");
        if(withoutAPIHost[0].indexOf(".dita")===-1){
          // console.log(withoutAPIHost);
          if(sp.dartSelectors.ccmsversion.selected==="s1") 
            withoutAPIHost[0]=withoutAPIHost[0].replace(/_/g,"%20");

          if(withoutAPIHost.join("/").indexOf("cdn.ckeditor")===-1)
            fileURL = apiHost+withoutAPIHost.join("/");
          // console.log(117,fileURL);
          //fetch referencefiles from preprocess
        }
      }
    } catch (error) {
      console.log(error);
    }
    fileURL = fileURL.replace("//quicklinks","/quicklinks").replace(".txt",".txt"); // remove doubleslashes appearing on quicklinks. TODO: find out why?
    // if(window.location.href.indexOf("localhost/")>-1){console.log(162,"counterAPI-fileURL:",fileURL);}
    // // alert("176,counterapi:fileURL="+fileURL);
    // fileURL = fileURL.replace('https://ccmsfiles.tg.org.au/','/').replace('https://api.tg.org.au/','/')
    // fileURL = fileURL.replace('https://ccmsfiles.tg.org.au/','//localhost/').replace('https://api.tg.org.au/','/localhost/')
    // console.log(fileURL);
    $.ajax({
      type: "GET",
      url: fileURL,
      crossDomain: true,
      headers,
      success: function (data) {
        // if(sp.topicfile.indexOf(".xml")>-1)console.log("Ajax response:", data);
        // console.log(171,"counterapi:::",JSON.stringify(data));
      },
      error: function (data, error, status) {
        // console.log(sp.topicfile,JSON.stringify(data), error, status);
        // console.log("184,counterapi-status:"+status);
      },
    }).then(function (data) {
      try{data = new XMLSerializer().serializeToString(data);}catch(e){}
      data = cleanDITA(data,fileURL);
      // console.log(data);

      // let writekey=sp.topicfile;
      // if(data.indexOf("Request failed with status code 429")>-1){
      //   writekey = "Error_"+writekey
      // }else{
      //   WriteToIDB(writekey,data);
      // }
      resolve({ data })
    });

    }
  );
}

export class CacheManager {
  constructor(config) {
    console.log("iDB",config);
    localforage.config(config);
  }

  keyFile = (key) => (key.indexOf("/")===-1)?key:key.split("/").slice(-1)[0];

  write = (key,data)=>localforage.setItem(this.keyFile(key),data);
  read = (key)=>localforage.getItem(this.keyFile(key));
  remove = (key)=>localforage.removeItem(this.keyFile(key));
  clear = ()=>localforage.clear();
  keys = ()=>localforage.keys();
  config = (config)=>localforage.config(config); //{ name: dartSelectors.ccmsversion.selected }
}