import React from 'react';

const SortResults = ({ sortResults, setSortResults })=>{
  const handleChange = (event) => {    
    setSortResults(event.target.value);
  };
  
  return(
    <div id="sortResults" className="input-group sortResults">
    <div className="form-group">
      <select 
        id="sortBy" 
        className="sortOptions form-control"
        value={sortResults}
        onChange={handleChange}
      >
        <option value="0">Relevance</option>
        <option value="1">Title Ascending (A-Z)</option>
        <option value="2">Title Decending (Z-A)</option>
      </select>
    </div>
  </div>
  )
}

export default SortResults;