export function cleanDITA(ditaxml,fileURL){

    try {
        ditaxml = ditaxml.replace('<?xml version="1.0" encoding="UTF-8"?>',"");
        //ditaxml = ditaxml.replace('<!DOCTYPE concept PUBLIC "-//OASIS//DTD DITA Concept//EN" "concept.dtd">',""); 
        //ditaxml = ditaxml.replace('<!DOCTYPE map PUBLIC "-//OASIS//DTD DITA Map//EN" "map.dtd">',""); 
        //ditaxml = ditaxml.replace('<!DOCTYPE reference PUBLIC "-//OASIS//DTD DITA Reference//EN" "reference.dtd">',"");

        ditaxml = ditaxml.replace(/<!DOCTYPE[^>]*>/g, '');
        
        ditaxml = ditaxml.replace(/<title/g,"<ditatitle");
        ditaxml = ditaxml.replace(/\/title>/g,"/ditatitle>");
        
    
        //For tables
        ditaxml = ditaxml.replace(/<row/g,"<tr");
        ditaxml = ditaxml.replace(/\/row>/g,"/tr>");
        ditaxml = ditaxml.replace(/<entry/g,"<td");
        ditaxml = ditaxml.replace(/\/entry>/g,"/td>");
        ditaxml = ditaxml.replace(/nameend="col/g,'colspan="');
        ditaxml = ditaxml.replace(/morerows="/g,'rowspan="'); // needs to be +1. Set in code.
    } catch (error) {
        console.log(fileURL);
        console.log(window.location.href,error);
        console.log(ditaxml);
        // window.location.reload();
    }

    //Wrap fig in divs to prevent breaks
    if(ditaxml.indexOf("</fig></p>")>-1){
        ditaxml = ditaxml.replace(/<fig/g,"<div><fig");
        ditaxml = ditaxml.replace(/\/fig>/g,"/fig></div>");
        // console.log("figP......");
    }

    //for images

    // ditaxml=ditaxml.replace(/image outputclass\=\"tgl_figure\" href\=\"\.\.\/\.\.\//g,'img outputclass="tgl_figure" src="https://cdn.tg.org.au/ccms/');

    return ditaxml;
}
