/* eslint-disable */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setItemsPerPage, 
  selectItemsPerPage, 
  setCurrentPage, 
  selectCurrentPage, 
} from '../../store/reducers/search';

const ItemsPerPage = (props) => {
    const itemsPerPage = useSelector(selectItemsPerPage);
    const dispatch = useDispatch();
    
   
  
    const handleItemsChange = (event) => {
      dispatch(setCurrentPage(1));
      dispatch(setItemsPerPage(parseInt(event.target.value)));
      // console.log("scroll to top...");
      setTimeout(() => {
        window.scrollTo({
          top: 100,
          behavior: 'smooth'
        });
      },500);

    };
  
    return (
      <div className="col-md-4 col-sm-3 col-xs-12">
        <form className="form-horizontal mTop40px pageCounter" data-gtm-form-interact-id="0">
          <div className="row itemperpage">
            <div className="col-md-4 col-xs-4">
              <label className="control-label">
                Items per page: 
              </label>
            </div>
            <div className="col-md-8  col-xs-8 noRightPadding">
              <select id="items" className="form-control" data-gtm-form-interact-field-id="0" value={itemsPerPage} onChange={handleItemsChange}>
                <option>10</option>
                <option>15</option>
                <option>25</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    );
  };
  
  const Pagination = (props) => {
    const itemsPerPage = useSelector(selectItemsPerPage);
    const currentPage = useSelector(selectCurrentPage);
    const dispatch = useDispatch();

    // console.log(props,itemsPerPage,currentPage);
      
        const handlePageChange = (pageNumber) => {

          if(pageNumber<1 || pageNumber>Math.ceil(props.resultcount/itemsPerPage)) return;
          
            dispatch(setCurrentPage(pageNumber));
            // console.log("scroll to top...");
            setTimeout(() => {
              window.scrollTo({
                top: 100,
                behavior: 'smooth'
              });
            },500);
        //   setCurrentPage(pageNumber);
        };
        const totalPages = Math.ceil(props.resultcount/itemsPerPage);
        let activePage = "jp-current";
        // console.log("totalPages",totalPages);

    return (
    <div className="col-md-8 col-sm-9 col-xs-12 holder">

      <a key="prev-0" className="jp-previous" onClick={() => handlePageChange(currentPage-1)}>← previous</a>

      <a key={1} className={(currentPage===1?activePage:"")} onClick={() => handlePageChange(1)}>1</a>
        
        {(currentPage-4<=1)?"":"..."}

      {Array.from({ length: totalPages }, (_, i) => i + 2).map(pageCount => (
        ((pageCount===currentPage && pageCount!==1 && pageCount<totalPages)
          || (pageCount<currentPage+4 && pageCount>currentPage-4  && pageCount!==1 && pageCount<totalPages )
          
        )
        ?<a className={(currentPage===pageCount?activePage:"")} key={pageCount} onClick={() => handlePageChange(pageCount)}>
          {pageCount}
        </a>
        :""
      ))}

        {(currentPage+4<totalPages)?"...":""}

        {(totalPages>1)?
        <a key={totalPages} className={(currentPage===totalPages?activePage:"")} onClick={() => handlePageChange(totalPages)}>{totalPages}</a>
        :""}
      <a key="next-0" className="jp-next" onClick={() => handlePageChange(currentPage+1)}>next →</a>
    </div>
  );
};
  
  const Container = (props) => {

    return (
    <div className="container">
      <div className='row'>
        <ItemsPerPage resultcount={props.resultcount} />
        <Pagination resultcount={props.resultcount}   />
      </div>
    </div>
  )};
  
  export default Container;