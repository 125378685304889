import $ from 'jquery';
import { validateAlphaNumericAll } from '../utils';

$.fn.highlight = function(pat) {
  function innerHighlight(node, pat) {
    var skip = 0;
    if (node.nodeType === 3) {
      var pos = node.data.toUpperCase().indexOf(pat);
      if (pos >= 0) {
        var spannode = document.createElement('span');
        spannode.className = 'highlight';
        var middlebit = node.splitText(pos);
        var middleclone = middlebit.cloneNode(true);
        spannode.appendChild(middleclone);
        middlebit.parentNode.replaceChild(spannode, middlebit);
        skip = 1;
      }
    } else if (node.nodeType === 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
      for (var i = 0; i < node.childNodes.length; ++i) {
        i += innerHighlight(node.childNodes[i], pat);
      }
    }
   
    return skip;
  }
  return this.each(function() {
    innerHighlight(this, pat.toUpperCase());
  });
};
 
$.fn.removeHighlight = function() {
  function newNormalize(node) {
    for (var i = 0, children = node.childNodes, nodeCount = children.length; i < nodeCount; i++) {
      var child = children[i];
      if (child.nodeType === 1) {
        newNormalize(child);
        continue;
      }
         
      if (child.nodeType !== 3) { continue; }
      var next = child.nextSibling;
      if (next === null || next.nodeType !== 3) { continue; }
      var combined_text = child.nodeValue + next.nodeValue;
      var new_node = node.ownerDocument.createTextNode(combined_text);
      node.insertBefore(new_node, child);
      node.removeChild(child);
      node.removeChild(next);
      i--;
      nodeCount--;
     }
  }
 
  return this.find("span.highlight").each(function() {
     var thisParent = this.parentNode;
     thisParent.replaceChild(this.firstChild, this);
     newNormalize(thisParent);
  }).end();
};

const print_html = (html) => {
  const blob = new Blob([html], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const printWindow = window.open(url, 'PrintWindow');
  printWindow.onload = function () {
    printWindow.focus();
    printWindow.print();
    setTimeout(function () {
      printWindow.close();
    }, 100);
    URL.revokeObjectURL(url);
  };
}

$.expr[":"].contains = $.expr.createPseudo(function(arg) {
  return function( elem ) {
    return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
  };
});

const useTopicButtons = () => {
  const printTopic = (e) => {
    e.preventDefault();
    $(".colapsecontent").show();
    const title = '';
    const bodyHtml = $('#contentId').html();
    const tglLogo = "/TherapeuticGuidelines_Original_Logo.svg";
    const html_content = `
    <html>
    <head>
        <title>Topic | Therapeutic Guidelines</title>
        <header>
            <div class="col-lg-3 col-xs-4 col-sm-4">
                  <svg width="127" height="39">
                      <image xlink:href="${tglLogo}" width="127" height="39" alt="Therapeutic Guidelines"/>
                  </svg>
            </div>
        </header>
        <base href=${window.location.origin} />
        <link href="/css/tgstyle.css" rel="stylesheet" type="text/css" />
        <link href="/css/print.css" rel="stylesheet" type="text/css"/>
    </head>
    <body>
        <h1>${title}</h1>
        <div style="font-family: Times New Roman; font-size:16px; color: #000">${bodyHtml}</div>
        <br>
        <footer id="footer">
            <div class="container">
                <p class="copyright" style="font-family: FranklinGothicITCbyBT-Book; font-size:10px;">
                    Therapeutic Guidelines Limited (www.tg.org.au) is an independent not-for-profit organisation dedicated to deriving guidelines for therapy from the latest world literature, interpreted and distilled by Australia's most eminent and respected experts. &#169; Therapeutic Guidelines Ltd
                </p>
            </div>
        </footer>
    </body>
    </html>
    `;
    print_html(html_content);
  }

  const printDiv = (divName) => {
    // const title = $('#topicHighlight').val();
    const printContents = $("#" + divName).html();
    const tglLogo = "/TherapeuticGuidelines_Original_Logo.svg";
    const htmlContent = `
    <html>
      <head>
        <title>Topic | Therapeutic Guidelines</title>
        <header>
          <div class="col-lg-3 col-xs-4 col-sm-4">
            <svg width="127" height="39">
              <image xlink:href="${tglLogo}" width="127" height="39" alt="Therapeutic Guidelines"/>
            </svg>
          </div>
        </header>
        <base href=${window.location.origin} />
        <link href="/css/tgstyle.css" rel="stylesheet" type="text/css" />
        <link href="/css/print.css" rel="stylesheet" type="text/css"/>
      </head>
      <body>
        <div style="font-family: Times New Roman; font-size:16px; color: #000">${printContents}</div>
        <br>
        <footer id="footer">
          <div class="container">
            <p class="copyright" style="font-family: FranklinGothicITCbyBT-Book; font-size:10px;">
              Therapeutic Guidelines Limited (www.tg.org.au) is an independent not-for-profit organisation dedicated to deriving guidelines for therapy from the latest world literature, interpreted and distilled by Australia's most eminent and respected experts. © Therapeutic Guidelines Ltd
            </p>
          </div>
        </footer>
      </body>
    </html>
    `;
    print_html(htmlContent);
  }

  const getOpenSection = (openSection, xrefPath) => {
    let section;
    
    openSection.map((os) => {
      if (os === xrefPath) {
        section = os;
        return;
      }
    });

    return section;
  }

  const processFavourite = (xRefPath) => {
    let favourite;
    if (xRefPath.includes('/')) {
      const splittedString = xRefPath.split('/');
      favourite = splittedString[splittedString.length - 1];
    } else {
      favourite = xRefPath;
    }

    const replacements = [
      { pattern: /^c_/, replacement: '' },
      { pattern: /^r_/, replacement: '' },
      { pattern: /^(AMG|amg)_/i, replacement: '' },
      { pattern: /_topic_\d+/, replacement: '' },
      { pattern: /\.dita/g, replacement: '' },
      { pattern: /_/g, replacement: ' ' },
      { pattern: /-/g, replacement: ' ' },
    ];
    
    replacements.forEach(({ pattern, replacement }) => {
      favourite = favourite.replace(pattern, replacement);
    });
    
    return favourite;
  }

  const addNewFav = (favourite, toc, section = null) => {
    const topicLink = window.location.href.replace(window.location.origin, '');
    const transformedFavorite = favourite.toLowerCase().replace(/\s/g, '');

    let savedFavourites = {};

    // Check if savedFavourites exists in localStorage
    if (localStorage.savedFavourites) {
        savedFavourites = JSON.parse(localStorage.getItem('savedFavourites'));
    }

    const data = {
        topic: favourite.trim(),
        ditatitle: '',
        href: section ? topicLink : `/viewTopic/?guidelinePage=${encodeURIComponent(toc)}&etgAccess=true`
    };

    if (section) {
        data.section = section;
    }

    console.log("Adding new favourite: ", transformedFavorite, data, savedFavourites)
    savedFavourites[transformedFavorite] = data;
    localStorage.setItem('savedFavourites', JSON.stringify(savedFavourites));
  };

  const removeFav = (topic) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmDeleteFav = confirm("Are you sure you want to delete?");
    if (confirmDeleteFav) {

      let savedFavourites = localStorage.getItem('savedFavourites');
      savedFavourites = JSON.parse(savedFavourites);

      const key = topic.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
      const current = savedFavourites[key];
      if (current) {
        delete savedFavourites[key];
        localStorage.setItem('savedFavourites', JSON.stringify(savedFavourites));
      }
    }
  }

  const expertModalFade = () => {
    if($("#expertgroup").hasClass('in')){

        $("#expertgroup").toggleClass('in');
        setTimeout(() => {
            $("#expertgroup").hide();
        }, 100);
    }
    else {
        $("#expertgroup").show(100);
        $("#expertgroup").toggleClass('in');
    }
  }

  const expertIcon = () => {
      $("#expertgroup").addClass('in');
      $("#expertgroup").show(100);
  }

  const ackModalFade = () => {
    if($("#acknowledgments").hasClass('in')){
        $("#acknowledgments").toggleClass('in');
        setTimeout(() => {
            $("#acknowledgments").hide();
        }, 100);
    }
    else {
        $("#acknowledgments").show(100);
        $("#acknowledgments").toggleClass('in');
    }
  }

  const toggleFontSize = (e) => {
    e.preventDefault();
    let topicontainerFont = 28;
    let h3font = 24;
    let ditacontentFont = 14;
    let ditacontentLH = 1.5;
    let fnFont = 12;
    let fontCounter = 0;
    if(fontCounter === -1){
      topicontainerFont = 28;
      h3font = 24;
      ditacontentFont = 14;
      fnFont = 12;
      fontCounter = 0;
      document.getElementById("javascriptCSS").innerHTML = "";
    } else {
      if (fontCounter === 0){
        fontCounter = 1;
        topicontainerFont = 32;
        h3font = 28;
        ditacontentFont = 15;
        ditacontentLH = 1.5;
        fnFont = 13;
      }
      else if (fontCounter === 1){
        fontCounter = 2;
        topicontainerFont = 36;
        h3font = 32;
        ditacontentFont = 16;
        ditacontentLH = 1.5;
        fnFont = 14;
      }
      else if (fontCounter === 2){
        fontCounter = -2;
        topicontainerFont = 20;
        h3font = 16;
        ditacontentFont = 12;
        ditacontentLH = 1.5;
        fnFont = 10;
      }
      else if (fontCounter === -2){
        fontCounter = -1;
        topicontainerFont = 24;
        h3font = 20;
        ditacontentFont = 13;
        ditacontentLH = 1.5;
        fnFont = 11;
      } 
      document.getElementById("javascriptCSS").innerHTML =
        "<style> @media (max-width: 767px) { h2.topicontainer { font-size: " + topicontainerFont + "px!important; } .showtandfsection h3 { font-size: " + h3font + "px!important; } conbody * { font-size: " + ditacontentFont + "px!important; line-height: " + ditacontentLH + "!important; } fn { font-size: " + fnFont + "px!important; } }</style>";
      return false;
    }
  }

  const removeHighlightSpecific = () => {
    let s = $(".bodyContentDiv").html();
    s=s.replaceAll('<span class="highlight">','');
    $(".bodyContentDiv").empty();
    $(".bodyContentDiv").html(s);
  }

  let crrSearchIndex = -1;
  let oldSearchKey = '';
  const search = (value) => {
    crrSearchIndex++;

    if(oldSearchKey !== value) {
      crrSearchIndex = 0;
      oldSearchKey = value;
    }

    if ($("section .colapsecontent").hasClass("colapsecontent") ) {
      $('section .colapsecontent:not(:contains(' + value + '))').hide();
      $('section .colapsecontent:not(:contains(' + value + '))').prev().children("h2").children("span").find("i").each(function() {
        $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
      });
    }
    
    $('h2:not(:contains(' + value + '))').show();
    if(value !== '') {
        $('section .colapsecontent:contains(' + value + ')').show(); 
    } else {
      if ($("section .colapsecontent").hasClass("colapsecontent")) {
        $('section .colapsecontent').hide();
      }
    }

    if ($("section .colapsecontent").hasClass("colapsecontent")) {
      $('section .colapsecontent').removeHighlight();
    } else {
      removeHighlightSpecific();
    }
      
    if(value) {
      if ($("section .colapsecontent").hasClass("colapsecontent")) {
        $('section .colapsecontent').highlight(value);
      } else {
        $('section').highlight(value);
      }
    }
  }

  const searchWithinPage = () => {
    if ($("section .colapsecontent").hasClass("colapsecontent")) { 
      if($("section .colapsecontent").find('.highlight').length > crrSearchIndex) {
        $("html,body").animate({'scrollTop': $("section").find('.highlight').eq(crrSearchIndex).offset().top}, 'slow');
        document.getElementById("searcher").focus();	                  
      } else{
        alert("No results found.");  
      }
    } else {
      //For Fatigue
      if($("section").find('.highlight').length > crrSearchIndex) {
        $("html,body").animate({'scrollTop': $("section").find('.highlight').eq(crrSearchIndex).offset().top}, 'slow');
        document.getElementById("searcher").focus();     
      } else{
        alert("No results found.");  
      }        
    }
  }

  const openFavModal = () => {
    if($("#myFavouritesModal").hasClass('in')) {
      $("#myFavouritesModal").toggleClass('in');
      setTimeout(() => {
        $("#myFavouritesModal").hide();
      }, 100);
    }
    else {
      $('.popover').hide();
      $("#myFavouritesModal").show(100);
      $("#myFavouritesModal").toggleClass('in');
      $(".modal-backdrop").show();
      $(".modal-backdrop").toggleClass('in');
      $('#favhiddenIDName').removeClass('faveFocus');
    }
  }

  const doneFav = () => {
    $('.popover').hide();
    $('viewall').hide();
    $('#favdone').hide();
    $('#favhiddenIDName').removeClass('faveFocus');
  }

  const displaySearch = () => {
    setTimeout( () => {
      let recentSearches = "";
      let savedData = JSON.parse(localStorage.getItem("savedSearches"));
  
      if(savedData !== null) {
        if(Object.keys(savedData).length > 0) {
          for(let prop in savedData) { //get arrays of saved search for all users
            let searchDT = savedData[prop];
            for (let inprop in searchDT) { //traverse all saved search of the logged in user
              if(searchDT[inprop].searchLabel !== undefined)
                recentSearches += "<li><button type='button' class='removeSaveSearch' aria-label='Close'><span aria-hidden='true' name='"+ searchDT[inprop].searchLabel +"'>×</span></button>&nbsp;<a href='" + searchDT[inprop].searchQuery + "' class='savedSearchLink'>"+ searchDT[inprop].searchLabel +"</a></li>";
            }
          }
        }
        $('.recent-searches').append(recentSearches);
      }
     },100);
  }

  const cancelDiv = () => {
    $('#searchName').val('');
    $('#savSearch').removeClass("in");
    $('#savSearch').css("display","none");
    $('.modal-backdrop').removeClass("in");
    $('.modal-backdrop').css("display","none");
  }
  
  const saveSearch = () => {
    let saveSearchTerm = $('#searchName').val();
    let searchName = saveSearchTerm.toLowerCase().trim().replace(/\s/g,'');
    let searchURL = window.location.href.replace(window.location.origin,"");
    if (saveSearchTerm === "" || saveSearchTerm.length === 0 || saveSearchTerm === null) {
      alert("Please enter a search name.");
      document.getElementById("searchName").focus();
      return;
    }
    if(!validateAlphaNumericAll(saveSearchTerm)) {
      alert("Please enter a valid search name.");
      document.getElementById("searchName").focus();
      return;
    }
    if (saveSearchTerm.length > 150) {
      alert("Maximum length of search name is 150.");
      document.getElementById("searchName").focus();
      return;
    }
    else {
      $(document.body).css({'cursor' : 'wait'});
      let searchData1 = '"' + searchName + '":{"searchLabel":"' + saveSearchTerm + '","searchQuery":"' + searchURL + '"}'; //format of adding new search data
      let listCheck = $('.recent-searches li').length;
      if(listCheck > 0) { //if saved searches is not empty
        //checks for duplication
        let ctrFlag = 0;
        $('.recent-searches li').each(function(i, li) {
          if(searchName.toLowerCase() === $(li).find('a').text().toLowerCase()) {
            ctrFlag ++;
          }
        });
        if(ctrFlag > 0) {
          alert('Search name already exists. Please choose a new search name');
        }
        else {
          //extract all saved searches of the user
          $('.recent-searches').empty();
          let searchStr = localStorage.savedSearches.substring(2);
          let searchInsert = "[{" + searchData1 + "," + searchStr;
          localStorage.setItem("savedSearches", searchInsert);
          displaySearch();
          console.log(new Date(), "Additional Search has been saved.");         
          cancelDiv(); 
        }
      }
      else {
        localStorage.removeItem("savedSearches");
        let searchInsert = "[{" + searchData1 + "}]";
        localStorage.setItem("savedSearches", searchInsert);
        displaySearch();
        console.log(new Date(), "Additional Search has been saved.");         
        cancelDiv(); 
      }
      $(document.body).css({'cursor' : 'default'});
    }
  }

  return {
    printTopic,
    printDiv,
    getOpenSection,
    toggleFontSize,
    search,
    searchWithinPage,
    addNewFav,
    openFavModal,
    doneFav,
    processFavourite,
    removeFav,
    expertModalFade,
    expertIcon,
    ackModalFade,
    saveSearch,
    displaySearch,
    cancelDiv,
  }
};

export default useTopicButtons;
