import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchIndex: '',
  searchTerm: '',
  refineIndex: '',
  allResults: [],
  topicResults: [],
  drugResults: [],
  refineAll:[],
  refineTopic:[],
  refineDrug:[],
  searchFacets: [],
  paginatePage: "all",
  refineFacet:"",
  itemsPerPage: 10,
  currentPage: 1,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchIndex: (state, action) => {
      state.searchIndex = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
      state.paginatePage = "search";
    },
    setRefineIndex: (state, action) => {
      state.refineIndex = action.payload;
      if (state.refineIndex.length >= 3 || state.refineIndex.length === 0)
        state.paginatePage = "search";
    },
    setAllResults: (state, action) => {
      state.allResults = action.payload;
    },
    setResults: (state, action) => {
      state.topicResults = action.payload.topicResults;
      state.drugResults = action.payload.drugResults;
      state.allResults = action.payload.allResults;

      if (state.topicResults.length > 0) {
        state.paginatePage = "topics";
      } else if (state.drugResults.length > 0) {
        state.paginatePage = "drugs";
      } else {
        state.paginatePage = "all";
      }
    },
    setTopicResults: (state, action) => {
      state.topicResults = action.payload;
    },
    setDrugResults: (state, action) => {
      state.drugResults = action.payload;
    },
    setFacets: (state, action) => {
      state.searchFacets = action.payload;
    },
    setRefineFacet: (state, action) => {
      state.refineFacet = action.payload;
    },
    setRefineAllResults: (state, action) => {
      state.refineAll = action.payload;
    },
    setRefineTopicResults: (state, action) => {
      state.refineTopic = action.payload;
    },
    setRefineDrugResults: (state, action) => {
      state.refineDrug = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetResults: (state, action) => {
      state.allResults = state.topicResults = state.drugResults = [];
      state.paginatePage = "search";
    },
  }
});

export const selectSearchIndex = (state) => state.search.searchIndex;
export const selectSearchTerm = (state) => state.search.searchTerm;
export const selectRefineIndex = (state) => state.search.refineIndex;
export const selectAllResults = (state) => state.search.allResults;
export const selectRefineAllResults = (state) => state.search.refineAllResults;
export const selectTopicResults = (state) => state.search.topicResults;
export const selectRefineTopicResults = (state) => state.search.refineTopicResults;
export const selectDrugResults = (state) => state.search.drugResults;
export const selectRefineDrugResults = (state) => state.search.refineDrugResults;
export const selectFacets = (state) => state.search.searchFacets;
export const selectRefineFacet = (state) => state.search.refineFacet;
export const selectItemsPerPage = (state) => state.search.itemsPerPage;
export const selectCurrentPage = (state) => state.search.currentPage;
export const selectPaginatePage = (state) => state.search.paginatePage;

export const {
  setSearchIndex,
  setSearchTerm,
  setRefineIndex,  
  setAllResults,
  setRefineAllResults,
  setTopicResults,
  setRefineTopicResults,
  setDrugResults,
  setRefineDrugResults,
  setFacets,
  setRefineFacet,
  setItemsPerPage,
  setCurrentPage,
  setResults,
  resetResults,
} = searchSlice.actions;

export default searchSlice.reducer;