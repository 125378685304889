import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { selectTocId} from './features/counter/counterSlice';
// import './App.css';

const NavBar = React.lazy(() => import('./components/navBar'));
const MainContent = React.lazy(() => import('./components/mainContent'));
const TopicBanner = React.lazy(() => import('./components/topicBanner'));
const TopicToolsSticky = React.lazy(() => import('./components/topicToolsSticky'));
const Footer = React.lazy(() => import('./components/footer'));

const App = (props) => {
  const { pathname, search, push } = props;
  const [referencePopup, setReferencePopup] = useState('');
  const tocId = useSelector(selectTocId);
  // console.log(tocId,mspTopicFile[tocId]);

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} tocId={tocId} />
        <MainContent search={search} referencePopup={referencePopup} tocId={tocId} setReferencePopup={setReferencePopup} pathname={pathname} />
        <TopicToolsSticky pathname={pathname} />
        {!window.Microsoft && <Footer />}
        
      </Suspense>
    </>
  );
}

App.propTypes = {
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(App);
