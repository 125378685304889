/* eslint-disable */
import React, { Suspense } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { MsalAuthenticationTemplate } from "@azure/msal-react";

const NavBar = React.lazy(() => import('../../components/navBar'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const Footer = React.lazy(() => import('../../components/footer'));


const GenAI = (props) => {
  const { push } = props;
  return (
    <>
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
        <MsalAuthenticationTemplate>
          <div className="container" style={{ height: '50vh' }}></div>
          <Footer />
          <div style={{
              position: "fixed", 
              right: "0px", 
              height: "85vh", 
              width: "100%", 
              zIndex: 5000, 
              transform: "translateX(50%)", 
              bottom: "1%", 
              border: "1px solid #000", 
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)"
  }}>
            <iframe src="https://copilotstudio.microsoft.com/environments/b700d30d-5a38-ef40-986f-c7b777535ec1/bots/cr29c_fileInformationAssistant/webchat?__version__=2" frameborder="0" style={{width: "50%", height: "100%"}}></iframe>
          </div>
        </MsalAuthenticationTemplate>
    </Suspense>
    </>
  );
};

export default connect(null, { push })(GenAI);